<template>
    <div>
        <v-btn block depressed :disabled="disabled" :fixed="fixed" :bottom="bottom" :color="color" tile :class="customClass" :style="customStyle" :loading="loading" @click='click'>{{label}}</v-btn>
    </div>
</template>

<script>
    export default {
        name: "cx-button",
        props: {
            color: {
                type: String,
                default: "#25207a"
            },
            label: {
                type: String,
                default: "btn"
            },
            loading: {
                type: Boolean,
                default: false
            },
            fixed: {
               type: Boolean,
                default: false 
            },
            bottom: {
               type: Boolean,
                default: false 
            },
            customclass: {
                type: Object,
                default: () => {}
            },
            customStyle: {
                type: Object,
                default: () => {}
            },
            disabled: {
               type: Boolean,
                default: false 
            }
        },
        data() {
            return {
                // classe par défaut
                customClass : {
                            'white--text': true,
                            'mt-3': true, 
                            'font-weight-bold': true
                            }
            }
        },
        mounted() {
            // permet de rajouter des classes
            // properties a false pour désactiver une classe
            Object.assign(this.customClass,this.$props.Customclass)
        },
        methods: {
            // nécessaire pour pouvoir récupérer l'event click dans le parent
            click() {
                this.$emit("click")
            }
        },
    }
</script>
